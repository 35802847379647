import React from "react";
import { useState } from "react";
import "./ContactForm.css";
import { FaPaperPlane } from "react-icons/fa";
// import axios from "axios";

export default function Contact() {
  const [person, setPerson] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
  });
  // const [people, setPeople] = useState([]);

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setPerson({ ...person, [name]: value });
  }

  return (
    <article className="contactPage" id="contactPisma">
      <form
        className="ContactForm"
        method="POST"
        name="fastContactForm"
        data-netlify="true"
        onSubmit="submit"
      >
        <input type="hidden" name="form-name" value="fastContactForm" />
        <div className="ContactFormTitles">
          <h3>Contact us</h3>
          <p>
            If you have any questions or comments, please don't hesitate to
            reach out to us. <br />
            We're always happy to help in any way we can.
          </p>
        </div>
        <div className="ContactInputNC">
          <input
            type="text"
            id="ContactName"
            name="name"
            value={person.name}
            onChange={handleChange}
            placeholder="What's your name? *"
            required
          />
          <input
            type="text"
            id="ContactCompany"
            name="company"
            value={person.company}
            onChange={handleChange}
            placeholder="Your company (if applicable)"
          />
        </div>
        <div className="ContactInputEP">
          <input
            type="email"
            id="ContactEmail"
            name="email"
            value={person.email}
            onChange={handleChange}
            placeholder="Enter your email *"
            required
          />
          <input
            type="text"
            id="ContactPhone"
            name="phone"
            value={person.phone}
            onChange={handleChange}
            placeholder="Enter your phone *"
            required
          />
        </div>
        <div className="ContactInputM">
          <textarea
            type="text"
            id="ContactMessage"
            name="message"
            value={person.message}
            onChange={handleChange}
            placeholder="Write your message *"
            required
          />
        </div>

        <button
          className="SubmitButton"
          // onClick={handleSubmit}
          type="submit"
        >
          send
        </button>
      </form>
      <div className="contactRight">
        <h3>Pisma</h3>
        <h5 className="footer-email">
          <FaPaperPlane className="email-icon" />
          <button
            className="contact-btn"
            onClick={() => window.location.assign("/contact-us")}
          >
            Get in Touch
          </button>
        </h5>
        <a
          href="https://www.facebook.com/profile.php?id=100078390273752"
          target="_blank"
          rel="noopener noreferrer"
          className="socialMediaTxt"
        >
          Facebook
        </a>
        {" | "}
        <a
          href="https://www.instagram.com/pisma.co.uk/"
          target="_blank"
          rel="noopener noreferrer"
          className="socialMediaTxt"
        >
          Instagram
        </a>
        {" | "}
        <a
          href="https://twitter.com/Pisma_UK"
          target="_blank"
          rel="noopener noreferrer"
          className="socialMediaTxt"
        >
          Tweeter
        </a>
        {" | "}
        <a
          href="https://www.linkedin.com/in/pisma-uk-7b0b09253/"
          target="_blank"
          rel="noopener noreferrer"
          className="socialMediaTxt"
        >
          LinkedIn
        </a>
        <p>
          If exceptional services are what you seek, your search ends here! We
          offer a comprehensive suite of solutions designed to meet your
          specific needs. Our commitment to excellence ensures that you'll
          receive nothing short of the best. We collaborate closely with you to
          ensure your complete satisfaction. Don't wait—reach out to us today
          and experience the transformative impact we can have on your business!
        </p>
      </div>
    </article>
  );
}
