import React from "react";
import "./styles/ProjectProcess.css";

export default function ProjectProcess() {
  return (
    <article className="ProjectProcess">
      <div className="PPleftSide">
        <h3>PROJECT PROCESS</h3>
        <p>
          As evident, this constitutes a pivotal facet of every project.
          Embracing these steps assures the triumph of your endeavor. Therefore,
          do not hesitate to implement them in your next project, ensuring its
          resounding success.
        </p>
        {/* <button
          className="PPbuttonLM"
          onClick={() => window.location.assign("/project-information")}
        >
          Learn More
        </button> */}
      </div>
      <div className="PPrightSide">
        <div className="PPstep">
          <img
            src={require("./images/PP/bulb-ga8fdcb2bb_1280.png")}
            alt="DISCOVERY"
          />
          <h4>DISCOVERY</h4>
          <p>
            After conducting essential meetings with the client, the Pisma team
            will strategize to formulate a plan that ensures optimal results.
          </p>
        </div>
        <div className="PPstep">
          <img
            src={require("./images/PP/clock-g076067533_1280.png")}
            alt="PLANING"
          />
          <h4>PLANING</h4>
          <p>
            The initial phase of a successful project centers around meticulous
            planning. This involves charting the necessary steps to be taken and
            outlining the prototypes that need to be developed.
          </p>
        </div>
        <div className="PPstep">
          <img
            src={require("./images/PP/backend-4496461_1280.png")}
            alt="DESIGN"
          />
          <h4>DESIGN</h4>
          <p>
            Employing the appropriate platforms, the design will breathe life
            into the final template, culminating in the end result.
          </p>
        </div>
        <div className="PPstep">
          <img
            src={require("./images/PP/database-schema-1895779_1280.png")}
            alt="DEVELOPMENT"
          />
          <h4>DEVELOPMENT</h4>
          <p>
            This marks the stage where dreams transform into reality. Armed with
            the essential data, the Pisma team is primed and prepared to bring
            this vision to life!
          </p>
        </div>
        <div className="PPstep">
          <img
            src={require("./images/PP/list-g74932ae0d_1280.png")}
            alt="TESTING"
          />
          <h4>TESTING</h4>
          <p>
            Every project's readiness hinges on undergoing a comprehensive
            testing phase to ensure its absolute perfection.
          </p>
        </div>
        <div className="PPstep">
          <img
            src={require("./images/PP/mailbox-gfb4e9f932_1280.png")}
            alt="PROJECT DELIVERY"
          />
          <h4>PROJECT DELIVERY</h4>
          <p>
            With meticulous preparation and rigorous testing complete, the time
            has come to witness your dream materialize into reality!
          </p>
        </div>
      </div>
    </article>
  );
}
