import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navigation.css"; // Importing the CSS file for styling
import logo from "./Pisma-logo.png";

const Navigator = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");

  useEffect(() => {
    const path = location.pathname;

    if (path === "/") {
      setActiveLink("home");
    } else if (path.startsWith("/about")) {
      setActiveLink("about");
    } else if (path.startsWith("/services")) {
      setActiveLink("services");
    } else if (path.startsWith("/pisma-blog")) {
      setActiveLink("pisma-blog");
    } else if (path.startsWith("/contact-us")) {
      setActiveLink("contact-us");
    } else {
      // Default to home if none of the above conditions match
      setActiveLink("home");
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsScrolled(offset > 10);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className={`navbar ${isScrolled ? "scrolled" : ""}`}>
      <div className="nav-brand">
        <Link
          to="/"
          className={activeLink === "home" ? "active-link" : "non-active-link"}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <img src={logo} alt="Company Logo" className="nav-logo" />
        </Link>
      </div>
      <ul className="nav-links">
        <li>
          <Link
            to="/"
            className={
              activeLink === "home" ? "active-link" : "non-active-link"
            }
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className={
              activeLink === "about" ? "active-link" : "non-active-link"
            }
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            About Us
          </Link>
        </li>
        <li>
          <Link
            to="/services"
            className={
              activeLink === "services" ? "active-link" : "non-active-link"
            }
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Services
          </Link>
        </li>
        <li>
          <Link
            to="/pisma-blog"
            className={
              activeLink === "pisma-blog" ? "active-link" : "non-active-link"
            }
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Our Blog
          </Link>
        </li>
        <li>
          <Link
            to="/contact-us"
            className={
              activeLink === "contact-us" ? "active-link" : "non-active-link"
            }
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Contact Us
          </Link>
        </li>
      </ul>

      <button
        className={`menu-button ${menuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        {/* Hamburger Icon */}
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </button>
      <div className={`menu-container ${menuOpen ? "open" : ""}`}>
        <ul className="menu-list">
          <li>
            <Link
              to="/"
              className={
                activeLink === "home"
                  ? "active-link hamburgerNav"
                  : "non-active-link hamburgerNav"
              }
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className={
                activeLink === "about"
                  ? "active-link hamburgerNav"
                  : "non-active-link hamburgerNav"
              }
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              className={
                activeLink === "services"
                  ? "active-link hamburgerNav"
                  : "non-active-link hamburgerNav"
              }
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/pisma-blog"
              className={
                activeLink === "pisma-blog"
                  ? "active-link hamburgerNav"
                  : "non-active-link hamburgerNav"
              }
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Our Blog
            </Link>
          </li>
          <li>
            <Link
              to="/contact-us"
              className={
                activeLink === "contact-us"
                  ? "active-link hamburgerNav"
                  : "non-active-link hamburgerNav"
              }
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigator;
