import React from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import Footer from "../../Utils/Footer/Footer";
import "./styles/BlogPage.css";
export default function Blog() {
  return (
    <article>
      <Navigator />
      <div className="ServicesHeroSection">
        <h1>Our Blog</h1>
        <p>Find out everything you need to know.</p>
      </div>
      <div className="PismaBloRowS">
        <div className="FirstBlogInTheRow">
          <div
            className="FirstBlogInTheRowLeft"
            onClick={() => window.location.assign("/pisma-blog/development")}
          >
            <img
              src={require("../Home/images/programmer-1653351_1280.png")}
              alt="img"
              className="FirstBlogInTheRowLeftImg"
            />
            <div className="FirstBlogInTheRowLeftTitles">
              <h5>Development</h5>
              <h2>Learn how a good development team works</h2>
            </div>
          </div>
          <p className="FirstBlogInTheRowRight">
            In personal and professional life, computer technology impacts even
            the simplest, everyday tasks. Whether through online bill payment or
            the seamless integration of global businesses, computer technology
            offers...
          </p>
        </div>

        <div className="SecondBlogInTheRow">
          <div
            className="FirstBlogInTheRowLeft"
            onClick={() => window.location.assign("/pisma-blog/IT-management")}
          >
            <img
              src={require("../Home/images/presentation-6732372_1280.png")}
              alt="img"
              className="FirstBlogInTheRowLeftImg"
            />
            <div className="FirstBlogInTheRowLeftTitles">
              <h5>IT Management</h5>
              <h2>Top 7 Ways A Knowledge Can Improve Your Office</h2>
            </div>
          </div>
          <p className="FirstBlogInTheRowRight">
            One of your employees has just tried to print something and he/she
            gets a printer error. The error says, "Out of paper." Well the
            employee knows that the printer is not out of paper and it does not
            have a paper jam. What to do?...
          </p>
        </div>
      </div>
      <div className="PismaBloRowS">
        <div className="FirstBlogInTheRow">
          <div
            className="FirstBlogInTheRowLeft"
            onClick={() => window.location.assign("/pisma-blog/AP-CSP")}
          >
            <img
              src={require("./images/data-g3f3a0e6f0_1280.png")}
              alt="img"
              className="FirstBlogInTheRowLeftImg"
            />
            <div className="FirstBlogInTheRowLeftTitles">
              <h5>AP CSP Course</h5>
              <h2>New Data: Diversifying Computer Science Pipeline</h2>
            </div>
          </div>
          <p className="FirstBlogInTheRowRight">
            For too long there has been a shortage of women and a lack of
            diversity in both computer science classrooms and in the workforce.
            AP Computer Science Principles, or AP CSP, was introduced...
          </p>
        </div>

        <div className="SecondBlogInTheRow">
          <div
            className="FirstBlogInTheRowLeft"
            onClick={() => window.location.assign("/pisma-blog/marketing")}
          >
            <img
              src={require("./images/website-g163e48a35_1280.png")}
              alt="img"
              className="FirstBlogInTheRowLeftImg"
            />
            <div className="FirstBlogInTheRowLeftTitles">
              <h5>Marketing</h5>
              <h2>Your Marketing Funnel</h2>
            </div>
          </div>
          <p className="FirstBlogInTheRowRight">
            Do you often wonder why it is that some coaches and other solo
            service providers seem to be well-known and sought after in their
            niche and while others...
          </p>
        </div>
      </div>
      <div className="PismaBloRowS">
        <div className="FirstBlogInTheRow">
          <div
            className="FirstBlogInTheRowLeft"
            onClick={() => window.location.assign("/pisma-blog/computers")}
          >
            <img
              src={require("./images/programming-gd7cb3ddef_1280.png")}
              alt="img"
              className="FirstBlogInTheRowLeftImg"
            />
            <div className="FirstBlogInTheRowLeftTitles">
              <h5>Computers</h5>
              <h2>
                Computers: An Afterschool Necessity for Many Young Students
              </h2>
            </div>
          </div>
          <p className="FirstBlogInTheRowRight">
            Shopping for school supplies isn’t what it used to be. Sure, kids
            still need notebooks, binders and pens — but they won’t survive...
          </p>
        </div>

        <div className="SecondBlogInTheRow">
          <div
            className="FirstBlogInTheRowLeft"
            onClick={() => window.location.assign("/pisma-blog/career")}
          >
            <img
              src={require("./images/computer-g9e793053b_1280.png")}
              alt="img"
              className="FirstBlogInTheRowLeftImg"
            />
            <div className="FirstBlogInTheRowLeftTitles">
              <h5>New Career Opportunities</h5>
              <h2>Abound as Dependency on Computer Technology Grows</h2>
            </div>
          </div>
          <p className="FirstBlogInTheRowRight">
            In personal and professional life, computer technology impacts even
            the simplest, everyday tasks. Whether through online...
          </p>
        </div>
      </div>
      <div className="PismaBloRowS">
        <div className="FirstBlogInTheRow">
          <div
            className="FirstBlogInTheRowLeft"
            onClick={() => window.location.assign("/pisma-blog/open-source")}
          >
            <img
              src={require("./images/computer-g6be7ade8e_1280.png")}
              alt="img"
              className="FirstBlogInTheRowLeftImg"
            />
            <div className="FirstBlogInTheRowLeftTitles">
              <h5>Open Source</h5>
              <h2>Practical Use of Open Source Code Software</h2>
            </div>
          </div>
          <p className="FirstBlogInTheRowRight">
            As we started the project of our web site, we knew that the
            proprietary software costs would be too high for our financial
            resources. Our only option...
          </p>
        </div>

        <div className="SecondBlogInTheRow">
          <div
            className="FirstBlogInTheRowLeft"
            onClick={() => window.location.assign("/pisma-blog/security")}
          >
            <img
              src={require("./images/security-g9458fa62d_1920.jpg")}
              alt="img"
              className="FirstBlogInTheRowLeftImg"
            />
            <div className="FirstBlogInTheRowLeftTitles">
              <h5>Security</h5>
              <h2>Simple Steps - Protect your Computer Online</h2>
            </div>
          </div>
          <p className="FirstBlogInTheRowRight">
            So you've bought your computer and want to get online? It's not as
            simple as connect the wire and off you go these day's. The internet
            is a weird...
          </p>
        </div>
      </div>
      <div className="PismaBloRowS">
        <div className="FirstBlogInTheRow">
          <div
            className="FirstBlogInTheRowLeft"
            onClick={() => window.location.assign("/pisma-blog/SEO")}
          >
            <img
              src={require("./images/seo-gd73fd1d51_1280.png")}
              alt="img"
              className="FirstBlogInTheRowLeftImg"
            />
            <div className="FirstBlogInTheRowLeftTitles">
              <h5>SEO</h5>
              <h2>SEO Services, What To Look For</h2>
            </div>
          </div>
          <p className="FirstBlogInTheRowRight">
            Search engine optimization has evolved into a competitive, growing
            industry. There are plenty of hoaxes and scams on the web. It seems
            that as the Internet grows...
          </p>
        </div>

        <div className="SecondBlogInTheRow">
          <div
            className="FirstBlogInTheRowLeft"
            onClick={() => window.location.assign("/pisma-blog/registry")}
          >
            <img
              src={require("./images/digitization-g6d1b8e6c1_1280.png")}
              alt="img"
              className="FirstBlogInTheRowLeftImg"
            />
            <div className="FirstBlogInTheRowLeftTitles">
              <h5>Registry</h5>
              <h2>Fix Your Computer Registry</h2>
            </div>
          </div>
          <p className="FirstBlogInTheRowRight">
            The Windows Registry System. Basically, for all windows users, one
            of the most important things in a computer that needs backup is the
            windows registry. In windows...
          </p>
        </div>
      </div>
      <div className="PismaBloRowS">
        <div className="FirstBlogInTheRow">
          <div
            className="FirstBlogInTheRowLeft"
            onClick={() =>
              window.location.assign("/pisma-blog/protect-your-computer")
            }
          >
            <img
              src={require("./images/security-gdf6768504_1920.jpg")}
              alt="img"
              className="FirstBlogInTheRowLeftImg"
            />
            <div className="FirstBlogInTheRowLeftTitles">
              <h5>Protect Your Computer</h5>
              <h2>Are You Protecting Your Computer?</h2>
            </div>
          </div>
          <p className="FirstBlogInTheRowRight">
            You just bought yourself a new computer! Can't wait to get it out of
            the box. Maybe you have had a computer before and maybe...
          </p>
        </div>

        <div className="SecondBlogInTheRow">
          <div
            className="FirstBlogInTheRowLeft"
            onClick={() => window.location.assign("/pisma-blog/spyware")}
          >
            <img
              src={require("./images/software-g411188687_1280.png")}
              alt="img"
              className="FirstBlogInTheRowLeftImg"
            />
            <div className="FirstBlogInTheRowLeftTitles">
              <h5>Spyware</h5>
              <h2>Annoying Spyware And Its Elimination</h2>
            </div>
          </div>
          <p className="FirstBlogInTheRowRight">
            Nowadays, you might have noticed of a threatening word "Spyware".
            Spyware software is becoming annoyance for the companies. Spyware is
            likely to be verified...
          </p>
        </div>
      </div>
      <Footer />
    </article>
  );
}
