import React, { useState } from "react";
import includeData from "./IncludeData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/servicesInclude.css";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function ServicesInclude() {
  return (
    <article className="ServicesInclude">
      <h4 className="ourServicesInclude">INCLUDE</h4>
      <Slider {...settings} className="CarouselStyle">
        <SingleItem x={1} />
        <SingleItem x={2} />
        <SingleItem x={3} />
        <SingleItem x={4} />
        <SingleItem x={5} />
        <SingleItem x={6} />
        <SingleItem x={7} />
        <SingleItem x={8} />
        <SingleItem x={9} />
      </Slider>
    </article>
  );
}

const SingleItem = ({ x }) => {
  // eslint-disable-next-line
  const [item, setItem] = useState(includeData);

  const returnedItem = item.find((singleItem) => singleItem.id === x);

  return (
    <article className="singleItem">
      <img src={returnedItem.img} alt="services include" />

      <div className="itemTitle">{returnedItem.title}</div>
      <div className="itemDescription">{returnedItem.info}</div>
    </article>
  );
};
