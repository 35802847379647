import React from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import Footer from "../../Utils/Footer/Footer";
import "./styles/BlogPage.css";

export default function BlogDevelopment() {
  return (
    <article>
      <Navigator />
      <article className="blogArticle">
        <h1>Abound as Dependency on Computer Technology Grows</h1>
        <p>
          &nbsp;&nbsp;&nbsp;In personal and professional life, computer
          technology impacts even the simplest, everyday tasks. Whether through
          online bill payment or the seamless integration of global businesses,
          computer technology offers optimized productivity and the flow of
          commerce, making it a vital component of success for all
          businesses.This unwavering dependence on computer technology has
          spurred the growth of related fields, making the present a great time
          to earn a degree in computer information systems (CIS).
          <br />
          <br />
          &nbsp;&nbsp;&nbsp; According to the U.S. Bureau of Labor Statistics,
          employment in this field is projected to grow by 30 percent from 2008
          to 2018, much faster than the average of all occupations, opening the
          door for those with applicable skills to apply their talents and
          passion for computing and technology in the workplace.
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;"There are many diverse specializations within CIS,"
          says Paul Rader, professor, College of Engineering and Information
          Sciences at DeVry University’s Fremont, Calif., campus."Professionals
          trained in CIS can work within a variety of capacities at an
          organization or company."DeVry University (www.devry.edu) offers nine
          specializations through its Bachelor’s in Computer Information Systems
          degree program, including:Information SecurityAs more business and
          retail transactions are completed online, computer security
          specialists play a vital role in maintaining information security.{" "}
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;In this role, individuals are responsible for
          installing security software, monitoring for network security breaches
          and responding to cyber attacks. As hacking becomes an increasingly
          prominent crime, those specializing in this role have become a
          necessity in the business world. Web Development and AdministrationWeb
          developers have the opportunity to channel both creative and technical
          passions into the design, production and implementation of
          applications for websites.Webmasters or web administrators, on the
          other hand, are responsible for maintaining websites and their
          content. In addition, they collect and analyze activity data, traffic
          patterns and other metrics. <br />
          <br />
          &nbsp;&nbsp;&nbsp;System AdministratorNamed one of the best jobs for
          fast growth by CNNMoney.com, systems administrators ensure that all
          components of a network are working together in harmony. As
          globalization increases, companies rely more heavily on computer
          networks for business success, and demand for those with skills in
          this area is on the rise. Systems Analysis and IntegrationAn ideal
          role for challenge-seekers, systems analysts design and develop new
          systems, solve problems and integrate systems to perform more
          efficiently and effectively. <br />
          <br />
          &nbsp;&nbsp;&nbsp;They are also tasked with anticipating the needs of
          a company’s network hardware and ensuring it can meet present and
          future requirements.Regardless of the industry, all companies rely on
          individuals with expertise in CIS to continue to grow, develop new
          products and services and stay one step ahead of their market
          competitors.
        </p>
      </article>
      <Footer />
    </article>
  );
}
