import React, { useState, useRef, useContext } from "react";
// import { useLocation } from "react-router-dom";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
  const [activeButton, setActiveButton] = useState("AppBtn");
  const [displayContent, setDisplayContent] = useState({
    AppBtn: "",
    WebBtn: "none",
    AdvBtn: "none",
    SeoBtn: "none",
    ManagBtn: "none",
  });
  const ServicesRef = useRef(null);

  const scrollToServices = (selectedService) => {
    ServicesRef.current.scrollIntoView({ behavior: "smooth" });
    setActiveButton(selectedService);
    setDisplayContent({
      AppBtn: selectedService === "AppBtn" ? "" : "none",
      WebBtn: selectedService === "WebBtn" ? "" : "none",
      AdvBtn: selectedService === "AdvBtn" ? "" : "none",
      SeoBtn: selectedService === "SeoBtn" ? "" : "none",
      ManagBtn: selectedService === "ManagBtn" ? "" : "none",
    });
  };

  return (
    <AppContext.Provider
      value={{
        openModal,
        ServicesRef,
        displayContent,
        activeButton,
        setOpenModal,
        scrollToServices,
        setDisplayContent,
        setActiveButton,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
