import React from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import Footer from "../../Utils/Footer/Footer";
import "./styles/BlogPage.css";

export default function BlogDevelopment() {
  return (
    <article>
      <Navigator />
      <article className="blogArticle">
        <h1>Computers: An Afterschool Necessity for Many Young Students</h1>
        <p>
          &nbsp;&nbsp;&nbsp;Shopping for school supplies isn’t what it used to
          be. Sure, kids still need notebooks, binders and pens — but they won’t
          survive without the latest technology either, namely a computer.
          According to a recent survey commissioned by HP and conducted by
          Wakefield Research, computers become a significant part of homework
          assignments for children as young as 10 years old and students aged 6
          to 17 spend at least three hours a day on devices with Internet
          access. <br />
          <br />
          &nbsp;&nbsp;&nbsp;However, that much computer use at such a young age
          raises some concerns among parents. “If your child is about to hit
          double digits, computers are going to be a routine part of their
          afterschool homework,” explains Kevin Frost, Vice President, Volume
          Business Unit, HP. <br />
          <br />
          &nbsp;&nbsp;&nbsp;“HP shares parents’ online safety concerns and
          offers a variety of desktop and notebook PCs with some exclusive
          offers and deals for students getting ready to go back to school.” Can
          kids have fun and be safe online? Most parents want to guarantee
          safety instead of hoping for it. The survey reported that more than 28
          percent of parents have more faith in giving their child a credit card
          at the mall than leaving them home alone on a computer. In fact, over
          a third of parents surveyed believe children cruising the Internet
          unsupervised have more potential for danger than kids staying at a
          friend’s house without parental supervision. <br />
          <br />
          &nbsp;&nbsp;&nbsp;Despite these fears — and the reality that prolonged
          computer usage is the norm for many students — 66 percent of parents
          don’t take simple steps to protect their children online, such as
          using parental control software, and 67 percent don’t block websites
          they deem inappropriate. To keep your children safe while making sure
          they’re able to complete their school work, consider the following
          from HP: * Select the right computer. <br />
          <br />
          &nbsp;&nbsp;&nbsp;To keep an eye on young children’s computer use,
          consider a family desktop PC that you can keep in a centralized
          location in your home. For example, HP has two desktops with
          student-friendly features and a 60-day Norton Internet Security trial
          — the Pavilion 23 All-in-One and TouchSmart 320 All-in-One
          (www.shopping.hp.com). <br />
          <br />
          &nbsp;&nbsp;&nbsp;* Use parental control software. Be aware of what
          your kids are doing online, by setting time limits and restricting
          inappropriate websites. Select HP PCs come with up to two years of
          Norton Internet Security at no charge. * Talk to your kids. Your kids
          may be “digitally savvy,” but that doesn’t mean they don’t need to
          learn some Internet safety lessons from Mom and Dad. <br />
          <br />
          &nbsp;&nbsp;&nbsp; Talk to them about not sharing personal information
          or clicking on suspicious links.
        </p>
      </article>
      <Footer />
    </article>
  );
}
