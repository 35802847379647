import React from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import PismaIntro from "./PismaIntro";
import ServicesMenu from "./ServicesMenu";
import ServicesInclude from "./ServicesInclude";
import ProjectProcess from "./ProjectProcess";
import PismaTeam from "./PismaTeam";
import BlogMenu from "./BlogMenu";
import Reviews from "./Reviews";
import ContactForm from "../../Utils/ContactForm/ContactForm";
import Footer from "../../Utils/Footer/Footer";

function Home() {
  return (
    <article className="Home">
      <Navigator />
      <PismaIntro />
      <ServicesMenu />
      <ServicesInclude />
      <ProjectProcess />
      <PismaTeam />
      <BlogMenu />
      {/* <Reviews /> */}
      <ContactForm />
      <Footer />
    </article>
  );
}

export default Home;
