import React from "react";
import "./styles/BlogHomePage.css";

export default function BlogMenu() {
  return (
    <article className="BlogHome">
      <h4 className="overviewBlogTitle">OUR BLOG</h4>
      <h4 className="ourBlogTitle">READ WHAT YOU NEED TO KNOW</h4>
      <article className="BlogHomePage">
        <div className="BlogLeftSide">
          <div className="PismaBlog">
            <div
              className="PismaBlogUp"
              onClick={() => window.location.assign("/pisma-blog")}
            >
              <img
                src={require("./images/man-1424877_1280.png")}
                alt="Pisma-blog"
                className="PismaBlogImg"
              />
              <div className="PismaBlogTitles">
                <h5>Pisma Blog</h5>
                <h2>Learn anything you need to know from our free Blog</h2>
              </div>
            </div>
            <p className="PismaBlogDown">
              The potential advantages of effective knowledge management are
              significant but, as with most processes, there are certain
              challenges to consider.
            </p>
          </div>
        </div>
        <div className="BlogRightSide">
          <div className="DevelopmentBlog">
            <div
              className="DevelopmentBlogUp"
              onClick={() => window.location.assign("/pisma-blog/development")}
            >
              <img
                src={require("./images/programmer-1653351_1280.png")}
                alt="Development-Blog"
                className="DevelopmentBlogImg"
              />
              <div className="DevelopmentBlogTitles">
                <h5>Development</h5>
                <h2>Learn how a good development team works</h2>
              </div>
            </div>
            <p className="DevelopmentBlogDown">
              In personal and professional life, computer technology impacts
              even the simplest, everyday tasks. Whether through online bill
              payment or the seamless integration of global businesses, computer
              technology offers...
            </p>
          </div>
          <div className="ITmanagementBlog">
            <div
              className="ITmanagementBlogUp"
              onClick={() =>
                window.location.assign("/pisma-blog/IT-management")
              }
            >
              <img
                src={require("./images/presentation-6732372_1280.png")}
                alt="IT-management-Blog"
                className="ITmanagementBlogImg"
              />
              <div className="ITmanagementBlogTitles">
                <h5>IT Management</h5>
                <h2>Top 7 Ways A Knowledge Can Improve Your Office</h2>
              </div>
            </div>
            <p className="ITmanagementBlogDown">
              One of your employees has just tried to print something and he/she
              gets a printer error. The error says, "Out of paper." Well the
              employee knows that the printer is not out of paper and it does
              not have a paper jam. What to do?...
            </p>
          </div>
        </div>
      </article>
    </article>
  );
}
