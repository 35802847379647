import React from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import Footer from "../../Utils/Footer/Footer";
import "./styles/BlogPage.css";

export default function BlogDevelopment() {
  return (
    <article>
      <Navigator />
      <article className="blogArticle">
        <h1>Simple Steps - Protect your Computer Online</h1>
        <p>
          &nbsp;&nbsp;&nbsp;So you've bought your computer and want to get
          online? It's not as simple as connect the wire and off you go these
          day's. The internet is a weird and wonderful place as long as you can
          control what you do. <br />
          <br />
          &nbsp;&nbsp;&nbsp;That's why you need to protect yourself. These can
          be done in a number of very simple steps. This article will get you
          started but you should always try to be smart when your surfing the
          net! <br />
          <br />
          &nbsp;&nbsp;&nbsp;Step 1: <br />
          <br />
          &nbsp;&nbsp;&nbsp; Anti-Virus Scanner Whether you're connecting to the
          internet or not, you need an Anti-Virus scanner. Your computer can
          become infected with "viruses". These are little files or codes that
          have been written to ruin your computer! That's why they must be
          stopped! There are many programs out there that can help, but it's
          best to trust only the mainstream programs as these are used widely
          and updated regularly. <br />
          <br />
          &nbsp;&nbsp;&nbsp; Recommendations: AVG Anti-Virus Free
          http://free.grisoft.com/doc/1 Avast antivirus http://www.avast.com/
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;Step 2: <br />
          <br />
          &nbsp;&nbsp;&nbsp; Firewall Ok so your thinking what is a "firewall"
          sounds scary right? Well it's not really. It is again another line of
          defence against the number of ways your computer can be attacked. In
          simple terms it blocks other computers and programs from connecting to
          you and playing with your stuff. <br />
          <br />
          &nbsp;&nbsp;&nbsp;Think of it as an internet wall of fire, where you
          allow what can pass through. Software - Again there are many programs
          available to protect you, Recent Microsoft Windows machines come with
          Windows Firewall, which can be described as basic at best. We do
          recommend you go for mainstream again. Hardware - A firewall can also
          be included as part of your hardware, like an internet router "that's
          the box that sits between your pc and the internet line". This is the
          best and recommended way to have a firewall. As it blocks intruders
          before it gets to your pc. <br />
          <br />
          &nbsp;&nbsp;&nbsp; Recommendations: Zone Alarm
          http://www.zonelabs.com/store/content/company/products/znalm/freeDownload.jsp
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;Step 3: <br />
          <br />
          &nbsp;&nbsp;&nbsp; Be AWARE!! <br />
          <br />
          &nbsp;&nbsp;&nbsp; Simply put watch what you download and accept to
          save to your PC, sometimes people can send you files in e-mails. They
          look ok but when you run them they will install something onto your PC
          and leave you upset and distraught. <br />
          <br />
          &nbsp;&nbsp;&nbsp;Always scan something that has been downloaded
          before opening it and never open/run programs that have been sent in
          an e-mail from unknown sources. Step 4: Go for it That's pretty much
          it to get you going. If you think something doesn't look right, it
          probably isn't. <br />
          <br />
          &nbsp;&nbsp;&nbsp;Hopefully this will get you going to start with,
          wait till you find out about spy ware and Trojan's!! Good Luck and
          Safe Surfing
        </p>
      </article>
      <Footer />
    </article>
  );
}
