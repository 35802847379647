import React from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import Footer from "../../Utils/Footer/Footer";
import "./styles/BlogPage.css";

export default function BlogDevelopment() {
  return (
    <article>
      <Navigator />
      <article className="blogArticle">
        <h1>Are You Protecting Your Computer?</h1>
        <p>
          &nbsp;&nbsp;&nbsp;You just bought yourself a new computer! Can't wait
          to get it out of the box. Maybe you have had a computer before and
          maybe this is your second or third computer. <br />
          <br />
          &nbsp;&nbsp;&nbsp;And, you don't want to have all those problems you
          hear about. This is the time to think ahead and go for a positive
          online experience. You should realize that while you are unpacking
          your new machine, there are people all over the world who are plotting
          to spoil your computer with maliscious, well-thought out plans. Your
          computer will endure attacks moments after you go online. This is why
          we all have to use antivirus protection. <br />
          <br />
          &nbsp;&nbsp;&nbsp; You and I just have no choice. The single most
          important program on your computer is your anti virus program. And, it
          should be a really good one. Everything on your computer, from your
          valuable software, your work, to your personal financial records is
          contantly at risk. I will explain some of the features you should look
          for in anti-virus software. Most of us make our choice on price alone.
          That is, after we use the product that comes with our computers. This
          is not the way to choose something so important. <br />
          <br />
          &nbsp;&nbsp;&nbsp;You need to look at several products before making
          your decision. Look at your available options. Start with the anti
          virus software that came with your PC. You can get started by getting
          that software up and running. Don't try to postpone using anti-virus
          software. After a period of time, your software needs updated. While
          you are online, your software should be allowed to make daily updates.
          It is very important to make the first update as soon as your new
          computer is online for the first time. <br />
          <br />
          &nbsp;&nbsp;&nbsp;It is very likely there have been a lot of new
          threats and viruses introduced since the computer left the factory.
          Updating the software is the best way to keep your computer protected
          against these new online dangers. As you use the software that came
          with your new computer, it is a good idea to make a list of what you
          like - and don't like - about it. Is it easy to update? Is it user
          friendly? Is it easy to see any problems? Does the software notify you
          if the update could not be completed? Are updates made available on a
          daily basis? Now, you have a basis for establishing what to look for
          in anti-virus software. <br />
          <br />
          &nbsp;&nbsp;&nbsp;Read all you can online to get even more
          information. Some anti virus companies make their software available
          in trial versions, so be sure to check out a number of different
          products before deciding which one best suits your needs. <br />
          <br />
          &nbsp;&nbsp;&nbsp; Some of the best is free, without a trial. Take
          some time to get the best protection you can for your computer. And,
          periodically back up your files. Al little planning can make your
          computer experience an enjoyable experience.
        </p>
      </article>
      <Footer />
    </article>
  );
}
