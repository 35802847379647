import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../Home/styles/ServicesMenu.css";
import appIcons from "../Home/images/app-icons.webp";
import webIcons from "../Home/images/web-icons.webp";
import advertisingIcons from "../Home/images/advertising-icons.webp";
import SEOicons from "../Home/images/SEO-icons.webp";
import managementIcons from "../Home/images/management-icons.webp";
import appDev from "../Home/images/application.webp";
import webDev from "../Home/images/webDevelopment.webp";
import marketing from "../Home/images/marketing.webp";
import SEO from "../Home/images/SEO.webp";
import management from "../Home/images/management.webp";
import { useGlobalContext } from "../../Content/Content";

export default function ServicesMenu() {
  const {
    ServicesRef,
    displayContent,
    setDisplayContent,
    activeButton,
    setActiveButton,
    scrollToServices,
  } = useGlobalContext();

  const location = useLocation();

  useEffect(() => {
    console.log(location);
    if (location.state?.scrollTo) {
      console.log(location.state.scrollTo);
      scrollToServices(location.state.scrollTo);
    }
    // Add scrollToServices to the dependency array if it's not guaranteed to be stable
  }, []);

  const handleClick = (event) => {
    setActiveButton(event.currentTarget.id);
    setDisplayContent({
      AppBtn: event.currentTarget.id === "AppBtn" ? "" : "none",
      WebBtn: event.currentTarget.id === "WebBtn" ? "" : "none",
      AdvBtn: event.currentTarget.id === "AdvBtn" ? "" : "none",
      SeoBtn: event.currentTarget.id === "SeoBtn" ? "" : "none",
      ManagBtn: event.currentTarget.id === "ManagBtn" ? "" : "none",
    });
  };

  return (
    <article className="ServicesMenu">
      <h4 className="overviewServices">OVERVIEW</h4>
      <h4 className="ourServices">OUR SERVICES</h4>
      <menu className="theMenu">
        <div
          className={
            activeButton === "AppBtn" ? "theMenuBtnActive" : "theMenuBtn"
          }
          id="AppBtn"
          onClick={handleClick}
        >
          <img src={appIcons} alt="Application Development" />
        </div>
        <div
          className={
            activeButton === "WebBtn" ? "theMenuBtnActive" : "theMenuBtn"
          }
          id="WebBtn"
          onClick={handleClick}
        >
          <img src={webIcons} alt="Website Development" />
        </div>
        <div
          className={
            activeButton === "AdvBtn" ? "theMenuBtnActive" : "theMenuBtn"
          }
          id="AdvBtn"
          onClick={handleClick}
        >
          <img src={advertisingIcons} alt="Marketing" />
        </div>
        <div
          className={
            activeButton === "SeoBtn" ? "theMenuBtnActive" : "theMenuBtn"
          }
          id="SeoBtn"
          onClick={handleClick}
        >
          <img src={SEOicons} alt="seo" />
        </div>
        <div
          className={
            activeButton === "ManagBtn" ? "theMenuBtnActive" : "theMenuBtn"
          }
          id="ManagBtn"
          onClick={handleClick}
        >
          <img src={managementIcons} alt="IT-management" />
        </div>
      </menu>
      <div className="infoPerMenuBtn" ref={ServicesRef}>
        <div className="webServ" style={{ display: displayContent.AppBtn }}>
          <img
            src={appDev}
            alt="Application Development"
            style={{ height: "100%" }}
          />
          <div className="servBtnInfo">
            <h3>Application Development</h3>
            <p>
              &ensp; The main advantage of integrating an application into your
              business operations is enhanced accuracy in estimations. Specific
              tasks in your workflow may be limited to certain devices or
              contexts, making it crucial to have an effective tracking
              mechanism. An application can streamline this process, providing
              real-time data that helps you make more informed decisions,
              thereby optimizing performance and reducing overhead.
              <br />
              &ensp; Our application development include:
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  fontWeight: "900",
                }}
              >
                –
              </span>
              &ensp;Exquisite Design: The essence of quality lies in aesthetics,
              a principle deeply ingrained in Pisma's philosophy. Consequently,
              every project we undertake embodies this principle, creating a
              seamless fusion of form and function that mirrors the client's
              vision.
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  fontWeight: "900",
                }}
              >
                –
              </span>
              &ensp; User and Admin-Friendly: Our software services prioritize
              user-friendliness for both end-users and administrators. We
              understand that usability is key, regardless of whether the user
              is an admin or an end user.
              <br />
              <br />
              <span style={{ color: "red", fontWeight: "900" }}>– </span>{" "}
              &ensp;Unwavering Performance: Our benchmark for success is
              high-performance. Our apps are meticulously crafted to deliver
              exceptional performance across all devices. This is a cornerstone
              of our Pisma guarantee.
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  fontWeight: "900",
                }}
              >
                –
              </span>
              &ensp; Rigorous Testing: Prior to delivery, every project is
              subjected to rigorous testing. Our commitment to excellence
              ensures that only successful tests pave the way for client
              handover.
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  fontWeight: "900",
                }}
              >
                –
              </span>
              &ensp;Seamless Updates: We provide you with ownership of your
              application's code. Crafted professionally, the code is designed
              for effortless updates—whether executed by the original developer
              or a new one.
            </p>

            <Link
              className="ServiceButtonMoreHP"
              to="/contact-us"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <button className="ServiceButtonMore"> Learn more</button>
            </Link>
          </div>
        </div>
        <div className="webServ" style={{ display: displayContent.WebBtn }}>
          <img src={webDev} alt="Website Development" />
          <div className="servBtnInfo">
            <h3>Website Development</h3>
            <p>
              &ensp; We specialize in tailoring solutions to your needs,
              offering custom website design, web development, and online
              reputation management services. Whether you require a fresh
              website, a sustained social media presence, or a restoration of
              your reputation, we have the ideal solution waiting for you.
              <br /> <br />
              &ensp; When crafting a website, simplicity is key. There's no need
              for hot pink backgrounds, screen-consuming images, or neon yellow
              text. Time-tested clean design remains the most potent form of
              self/business marketing.
              <br />
              &ensp; Our website development include:
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  fontWeight: "900",
                }}
              >
                –
              </span>
              &ensp;Exquisite Design: The essence of quality lies in aesthetics,
              a principle deeply ingrained in Pisma's philosophy. Consequently,
              every project we undertake embodies this principle, creating a
              seamless fusion of form and function that mirrors the client's
              vision.
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  fontWeight: "900",
                }}
              >
                –
              </span>
              &ensp; SEO Mastery: Vital to any website is SEO—it's the gateway
              to securing top rankings on search engines like Google. At Pisma,
              we're committed to providing you with unparalleled SEO solutions
              for your website.
              <br />
              <br />
              <span style={{ color: "red", fontWeight: "900" }}>– </span>{" "}
              &ensp;Your Vision, Our Creation: No software challenge is
              insurmountable. Present us with your requirements, and we'll
              fashion precisely what you envision, no compromises.
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  fontWeight: "900",
                }}
              >
                –
              </span>
              &ensp; Rigorous Testing: Prior to delivery, every project is
              subjected to rigorous testing. Our commitment to excellence
              ensures that only successful tests pave the way for client
              handover.
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  fontWeight: "900",
                }}
              >
                –
              </span>
              &ensp;User and Admin-Friendly: Our software services prioritize
              user-friendliness for both end-users and administrators. We
              understand that usability is key, regardless of whether the user
              is an admin or an end user.
            </p>
            <h5>
              To dive deeper and discover how we can elevate your business,
              click the button below.{" "}
            </h5>

            <Link
              className="ServiceButtonMoreHP"
              to="/contact-us"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <button className="ServiceButtonMore"> Learn more</button>
            </Link>
          </div>
        </div>
        <div className="webServ" style={{ display: displayContent.AdvBtn }}>
          <img src={marketing} alt="Marketing" />
          <div className="servBtnInfo">
            <h3>Marketing for your business</h3>
            <p>
              &ensp; We offer a comprehensive approach to marketing strategy,
              creative design, and management. Our array of services spans
              advertising design, website design, SEO and SEO management,
              content creation, and social media engagement.
              <br />
              &ensp; Our Marketing project include:
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  fontWeight: "900",
                }}
              >
                –
              </span>
              &ensp;SEO Enhancement: SEO remains paramount for any website,
              being the avenue to secure top-ranking positions on search engines
              like Google. Our commitment to delivering premium SEO ensures that
              your website ranks prominently.
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  fontWeight: "900",
                }}
              >
                –
              </span>
              &ensp;Social Media Engagement: In today's landscape, social media
              is a potent business promotion tool. If you lack social media
              presence, we establish it for you. If you already have accounts,
              we leverage them to amplify your business's online prominence.
              <br />
              <br />
              <span style={{ color: "red", fontWeight: "900" }}>– </span>{" "}
              &ensp;Meta and Google Ads: For businesses in pursuit of increased
              customer reach, we offer versatile advertising packages across
              various price points and advertising levels. Our solutions
              encompass both Meta and Google advertisements.
            </p>
            <h5>
              To dive deeper and discover how we can elevate your business,
              click the button below.
            </h5>
            <Link
              className="ServiceButtonMoreHP"
              to="/contact-us"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <button className="ServiceButtonMore"> Learn more</button>
            </Link>
          </div>
        </div>
        <div className="webServ" style={{ display: displayContent.SeoBtn }}>
          <img src={SEO} alt="SEO" />
          <div className="servBtnInfo">
            <h3>SEO</h3>
            <p>
              &ensp; Search Engine Optimization (SEO) has evolved into a
              competitive and rapidly evolving industry. The online realm is
              rife with hoaxes and scams, with complexity on the internet giving
              rise to more intricate fraudulent activities. Selecting an
              effective SEO company can be a challenging task, given the
              multitude of factors involved in hiring such services.
              <br />
              <br />
              <span style={{ color: "red", fontWeight: "900" }}>– </span> &ensp;
              Keywords: Keywords hold paramount importance in effective SEO.
              These terms convey your website's essence to search engines.
              Algorithms assess the "Keyword Density" using the formula: Total
              Words ÷ Keywords = Keyword Density. <br />
              <br />
              <span style={{ color: "red", fontWeight: "900" }}>– </span>{" "}
              &ensp;URL Text: Naming a new page offers an opportunity to include
              keywords. The URL is the first element search engines encounter
              during page indexing. Given that content creation doesn't come
              naturally to all, taking advantage of straightforward
              opportunities to insert keywords is crucial.
              <br />
              <br />
              <span style={{ color: "red", fontWeight: "900" }}>– </span> &ensp;
              Description, Meta Tags: While Meta tags' significance has
              diminished, they still contribute to the overall SEO picture.
              Every bit counts in optimizing your website's performance.
              <br />
              <br />
              <span style={{ color: "red", fontWeight: "900" }}>– </span>{" "}
              &ensp;Title Tags: Title tags provide search engines with the
              formal description of your page or document. This phrase appears
              at the top of the browser window. Adhering to a crucial rule—limit
              title tags to keywords—provides an optimal platform to incorporate
              your keywords seamlessly. <br />
              <br />
              <span style={{ color: "red", fontWeight: "900" }}>– </span> &ensp;
              Image Names: Employ keyword-rich image names to enhance relevancy.
              For instance, a picture of a person working on a computer for a
              web design website could be named "web_site_design.jpg." This
              reinforces the image's connection to the website's content. <br />
              <br />
              <span style={{ color: "red", fontWeight: "900" }}>– </span>{" "}
              &ensp;ALT Tags: Attach keywords as ALT tags to images. Since
              search engines cannot analyze image content, this strategy
              bolsters an image's importance. <br />
              <br /> <span style={{ color: "red", fontWeight: "900" }}>
                –{" "}
              </span>{" "}
              &ensp; Heading Tags: Heading tags correspond to bold fonts
              introducing text sections. These tags should exclusively consist
              of keywords, mirroring the order of your Meta tags (H1 for the
              first meta tag, H2 for the second, and so on).
              <br />
              <br />
              &ensp;
              <br />
              &ensp;&ensp;&ensp; <br />
              <br />
              <br />
              <span style={{ color: "red", fontWeight: "900" }}>– </span> &ensp;
              Content: "Content is King," as every expert asserts. Each web page
              should host a minimum of 350 words, with more being better.
              However, consider keyword density to strike a balance. Avoid
              excessive text unrelated to keywords. <br />
              <br />
              <span style={{ color: "red", fontWeight: "900" }}>– </span>{" "}
              &ensp;HHyperlinks: Hyperlinks are essential for navigation and
              SEO. Key rules for hyperlinks include using them to guide search
              engines to the next page, employing keyword-rich phrases as link
              text, and enhancing link importance by attaching ALT tags to small
              images. <br /> •
              <br />• <br />•
            </p>
            <h5>
              To dive deeper and discover how we can elevate your business,
              click the button below.{" "}
            </h5>
            <Link
              className="ServiceButtonMoreHP"
              to="/contact-us"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <button className="ServiceButtonMore"> Learn more</button>
            </Link>
          </div>
        </div>
        <div className="webServ" style={{ display: displayContent.ManagBtn }}>
          <img src={management} alt="IT Management" />
          <div className="servBtnInfo">
            <h3>IT Management for your business</h3>
            <p>
              &ensp; IT management is a comprehensive service that encapsulates
              nearly every aspect of your technological landscape. It's a role
              that demands an acute attention to detail, driven by the
              increasing complexity and diversity of tasks faced by IT managers
              daily. The relentless pace of change in this field perpetuates the
              challenge. As businesses grow, they introduce new dimensions,
              necessitating IT managers to skillfully navigate growth while
              optimizing their management systems. Central to effective
              management is fostering enhanced communication among various
              stakeholders to harmonize understanding and requirements.
              <br />
              &ensp; Our IT management include:
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  fontWeight: "900",
                }}
              >
                –
              </span>
              &ensp;Organizational Efficiency: The foundation of success lies in
              an organized workplace. This pivotal factor sets the stage for
              everything else you aspire to achieve. By establishing an
              efficient workplace, you conserve energy and escalate
              productivity.
              <br />
              <br />
              <span
                style={{
                  color: "red",
                  fontWeight: "900",
                }}
              >
                –
              </span>
              &ensp; Software Analysis and Problem-Solving: Software analysis
              and modeling offer invaluable methodologies for comprehending
              challenges. This approach not only addresses software glitches but
              also enhances existing systems and crafts new solutions.
              <br />
              <br />
              <span style={{ color: "red", fontWeight: "900" }}>– </span>{" "}
              &ensp;Evaluation: Thorough evaluation of software ensures that it
              functions optimally within the business context.
              <br />
              <br />
              <span style={{ color: "red", fontWeight: "900" }}>– </span>{" "}
              &ensp;Software Development: Often, businesses possess an array of
              software programs that lack cohesion. Pisma's developers excel in
              identifying gaps and creating the requisite software to knit these
              programs together seamlessly.
              <br />
              <br />
              <span style={{ color: "red", fontWeight: "900" }}>– </span>{" "}
              &ensp;SEO Enhancement: In the digital realm, SEO is paramount to
              ensure your website's prominence. Ranking high on search engines,
              particularly Google, hinges on robust SEO practices. Pisma takes
              great care in delivering optimal SEO for your website.
            </p>
            <h5>
              To dive deeper and discover how we can elevate your business,
              click the button below.
            </h5>
            <Link
              className="ServiceButtonMoreHP"
              to="/contact-us"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <button className="ServiceButtonMore"> Learn more</button>
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
}
