import React from "react";
import "./footer.css";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaPaperPlane,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

export default function Footer() {
  const navigator = useNavigate();

  return (
    <footer className="footer">
      <div className="footer-section">
        <h3
          className="footer-heading"
          onClick={() => {
            window.scroll(0, 0);
            navigator("/contact-us");
          }}
        >
          Get In Touch
        </h3>
        <div className="social-container">
          <ul className="social-icons">
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=100078390273752"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FaFacebook />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/pisma.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FaInstagram />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/Pisma_UK"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FaTwitter />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/pisma-uk-7b0b09253/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FaLinkedin />
              </a>
            </li>
          </ul>
        </div>
        <h5 className="footer-email">
          <FaPaperPlane className="email-icon" />
          <button
            className="contact-btn"
            onClick={() => window.location.assign("/contact-us")}
          >
            Get in Touch
          </button>
        </h5>
      </div>
      <div className="footer-section">
        <h3 className="footer-heading">Quick Links</h3>
        <nav className="footer-nav">
          <ul>
            <li>
              <Link to="/project-information">Project Planner</Link>
            </li>
            <li>
              <Link to="/about">About Pisma</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/services/WebBtn#ServicesFullInfo">Get Website</Link>
            </li>
            <li>
              <Link to="/services/AppBtn#ServicesFullInfo">
                Get Application
              </Link>
            </li>
            <li>
              <Link to="/services/ManagBtn#ServicesFullInfo">
                Get IT Management
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="footer-section">
        <h3 className="footer-heading">From Our Blog</h3>
        <nav className="footer-nav">
          <ul>
            <li>
              <Link to="/pisma-blog">Pisma Blog</Link>
            </li>
            <li>
              <Link to="/pisma-blog/development">Development Blog</Link>
            </li>
            <li>
              <Link to="/pisma-blog/IT-management">IT Management Blog</Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}
