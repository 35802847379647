import React from "react";

import teamVideoDesign from "./images/team-video-design2.mp4";
import "./styles/PismaTeam.css";
export default function PismaTeam() {
  return (
    <article className="PismaTeam">
      <video
        src={teamVideoDesign}
        className="teamVideoDesign"
        autoPlay
        muted
        loop
      ></video>
      <div className="aboutThePismaTeam">
        <h2>Pisma</h2>
        <p>
          &ensp;At Pisma, our unique team composition sets us apart. Developers
          and marketers collaborate closely, ensuring that technical excellence
          and market insight are integrated into every project. This synergy
          drives superior results, making your digital presence both robust and
          impactful.
          <br />
          <br />
          &ensp;Our mission is to level the playing field by equipping people
          with cutting-edge technology. In an era where large corporations
          continue to dominate, we believe that small businesses also deserve a
          shot at success. While you may not be able to outpace large
          enterprises overnight, that's not the objective. Our aim is to help
          you showcase your unique offerings, using technology as a catalyst to
          gradually build your own empire. In doing so, you're not just
          surviving in today's digital landscape—you're thriving.
          <br />
          <br />
          &ensp;To make this mission a reality, we've strategically positioned
          ourselves with the right blend of expertise and competitive pricing.
          This allows us to extend our services to a broad range of clients,
          ensuring that anyone can benefit from our technological solutions.
          <br />
          <br />
          To discover more about how we can help you thrive in the digital
          landscape, click the button below.
        </p>
        <button
          className="aboutPismaTeam"
          onClick={() => window.location.assign("/about")}
        >
          About us
        </button>
      </div>
    </article>
  );
}
