import React from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import PismaTeam from "../Home/PismaTeam";
import ContactForm from "../../Utils/ContactForm/ContactForm";
import Footer from "../../Utils/Footer/Footer";
import PlanPhoto from "./images/job-g7c2d7f696_1920.jpg";
import workingPhoto from "./images/coding-g906973a6a_1920.jpg";
import Consistency from "./images/project-management-gfdda3d871_1920.jpg";
import Reliability from "./images/organization-chart-g0c874aada_1280.png";
import Experience from "./images/man-g1febc51fd_1280.png";
import "./styles/AboutPage.css";
import color184529 from "./images/color184529.png";
export default function AboutUs() {
  return (
    <article>
      <Navigator />
      <div style={{ margin: "5%" }}></div>
      <PismaTeam />
      <article>
        <div className="firstRow">
          <div className="rowDiv">
            <img
              src={Consistency}
              alt="Consistency"
              className="rowImageAboutPage"
            />
            <h1>Consistency</h1>
            <p>
              One of the pillars of Pisma is consistency, which is the only way
              to get the best results.
            </p>
          </div>
          <div className="rowDiv">
            <img
              src={Reliability}
              alt="Reliability"
              className="rowImageAboutPage"
            />
            <h1>Reliability</h1>
            <p>
              The most fundamental thing that concerns us is the reliability
              that we instill in our clients.
            </p>
          </div>
          <div className="rowDiv">
            <img
              src={Experience}
              alt="Experience"
              className="rowImageAboutPage"
            />
            <h1>Experience</h1>
            <p>
              Pisma team with lots of experience in the field are easily
              accessible to clients.
            </p>
          </div>
        </div>
        <div className="OnePartAboutPisma">
          <div className="oneFirstLeftSideIntro">
            <img
              className="oneFirstLeftSideIntroImg "
              src={PlanPhoto}
              alt="alt"
              data-aos="fade-down"
            ></img>
          </div>
          <div className="OneSecondRightSideIntro">
            <h2>What's the origin story of Pisma?</h2>
            <p>
              &ensp; Derived from the Greek word "πείσμα" which signifies a
              certain stubbornness or relentless focus on a goal, Pisma embodies
              this tenacity in our commitment to creation and helping others.
              For us, that "stubbornness a person has about something"
              "something" is the unwavering resolve to provide innovative
              solutions and meaningful support. That's the essence of what Pisma
              stands for.
              <br />
              <br /> &ensp; PPisma stands as a frontrunner in delivering
              cutting-edge solutions in software development. Our team of highly
              proficient experts is committed to ensuring top-tier results for
              our clientele. With an impressive track record of success and an
              unwavering commitment to excellence, we set the industry standard.
              Our focus is on delivering products and services of the utmost
              quality, always aiming to not just meet but exceed client
              expectations.
            </p>
          </div>
        </div>
        <div className="TwoPartAboutPisma">
          <div className="TwoFirstLeftSideIntro">
            <h2>Pisma team</h2>
            <p>
              &ensp; Our team is our greatest asset, embodying the youthful and
              dynamic spirit of our company. We operate with a flat hierarchy,
              fostering an environment that prioritizes open communication and
              collaboration. We firmly believe that employee satisfaction is
              intrinsically linked to customer happiness, which is why we invest
              heavily in training and development programs. This empowers our
              team to continually excel, thereby ensuring that our clients
              receive unparalleled service.
              <br />
              <br /> &ensp;Pisma operates with a diverse array of departments,
              and we prioritize making them easily accessible to one another. We
              hold the conviction that exceptional outcomes are the result of
              collective effort. By fostering an environment of seamless
              interdepartmental collaboration, we ensure that the full spectrum
              of our team's expertise is leveraged, delivering unparalleled
              results for our clients.
            </p>
          </div>
          <div className="TwoSecondRightSideIntro">
            <img
              className="TwoFirstLeftSideIntroImg "
              src={workingPhoto}
              alt="alt"
              data-aos="fade-down"
            ></img>
          </div>
        </div>
        <div className="secondRow">
          <div className="rowDiv">
            <h1>Solutions</h1>
            <p>
              When you have our consistent, reliable and experienced, finding
              the solution to a problem becomes very easy.
            </p>
          </div>
          <div className="rowDiv">
            <h1>Accuracy</h1>
            <p>
              You got whatever you need! The free meetings and the prototypes we
              make show how accurate we want to be.
            </p>
          </div>
          <div className="rowDiv">
            <h1>Fast results</h1>
            <p>
              Having a project ready in a timely manner when you need it is very
              important and we are proud of it.
            </p>
          </div>
        </div>
      </article>
      <div className="contactAboutPage">
        <img src={color184529} alt="backG" className="contactAboutPageImg" />
        <ContactForm />
      </div>
      <Footer />
    </article>
  );
}
