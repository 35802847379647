// import React, { Component } from 'react';
// import photo from './images/app-icons.webp';
const IncludeData = [
  {
    id: 1,
    img: require("./images/ServicesInclude/design.webp"),
    title: "Design",
    info: "All our project are carefully designed before being coded by experienced Pisma's team. And for the maximum results before the coding we always do a video call with our customer.",
  },
  {
    id: 2,
    img: require("./images/ServicesInclude/responsive-1166833_1280.webp"),
    title: "Mobile",
    info: "All our projects are working perfectly fine in phones if they need it, ensuring excellent mobile performance via React Native and Node JS technology. ",
  },
  {
    id: 3,
    img: require("./images/ServicesInclude/seo-7363717_1280.webp"),
    title: "SEO",
    info: "SEO is the practice of directing your website to rank higher.Many factors should be considered when optimizing a web page. Search engines use a variety of algorithms and their spiders may differ.",
  },
  {
    id: 4,
    img: require("./images/ServicesInclude/computer-2851285_1280.webp"),
    title: "CMS",
    info: "Many software developers choose to run their software on the powerful content management system (CMS). Pisma team is using the best CMS for Full Stuck: React Native, Express and Node JS.",
  },
  {
    id: 5,
    img: require("./images/ServicesInclude/shopping-cart-151685_1280.webp"),
    title: "E-commerce",
    info: "Electronic commerce has taken the world by storm. Many traditional industries are now shifting to e-commerce in order to stay competitive.E-commerce is a rapidly growing business sector that allows consumers to transact business electronically.",
  },
  {
    id: 6,
    img: require("./images/ServicesInclude/digital-5000690_1280.webp"),
    title: "Amazing Design",
    info: "Having a well-designed website or application is essential for success in today's digital world. Our team of experienced designers will create a stunning design that will help you stand out from the competition.",
  },
  {
    id: 7,
    img: require("./images/ServicesInclude/computer-1294045_1280.webp"),
    title: "Security",
    info: "Keep your software safe with Security! Security is the best way to keep your software safe from harm. Protect your investment today with Security!",
  },
  {
    id: 8,
    img: require("./images/ServicesInclude/social-media-432498_1280.webp"),
    title: "Social Media",
    info: "Grow your business with social media. Manage your Facebook, Twitter, LinkedIn, and Google+ accounts with our powerful tools and grow your audience, and connect with customers.",
  },
  {
    id: 9,
    img: require("./images/ServicesInclude/gears-148196_1280.webp"),
    title: "Custom Dev",
    info: "Need a custom solution? Our team of experts can help! From web development to app design, we can create a solution that's perfect for you.  From small tweaks to complex projects, we'll get the job done right - and on time.",
  },
];

export default IncludeData;
