import React from "react";
import Navigator from "../../Utils/Navigation/Navigator";

import { useEffect, useState, useRef } from "react";

import axios from "axios";
import "./styles/ContactUs.css";

export default function ContactUs() {
  const boxRef = useRef();
  const [boxHeight, setBoxHeight] = useState(0);
  const appRef = useRef();
  const webRef = useRef();
  const markRef = useRef();
  const seoRef = useRef();
  const itMRef = useRef();

  const [person, setPerson] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
    servicePackage: "",
    deadline: "",
    description: "",
    extra: "",
  });

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setPerson({ ...person, [name]: value });
  }

  useEffect(() => {
    setBoxHeight(boxRef.current.offsetHeight);
  }, []);

  function AllExtras() {
    let allExtra = "";

    if (appRef.current.checked) {
      allExtra = allExtra + " " + appRef.current.value + " &";
    }
    if (webRef.current.checked) {
      allExtra = allExtra + " " + webRef.current.value + " &";
    }
    if (markRef.current.checked) {
      allExtra = allExtra + " " + markRef.current.value + " &";
    }
    if (seoRef.current.checked) {
      allExtra = allExtra + " " + seoRef.current.value + " &";
    }
    if (itMRef.current.checked) {
      allExtra = allExtra + " " + itMRef.current.value + " &";
    }

    if (allExtra === "") {
      allExtra = " N/A ";
    }
    if (allExtra.endsWith("&")) {
      allExtra = allExtra.slice(0, -1);
    }
    return allExtra;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    const name = person.name;
    const company = person.company || " N/A ";
    const email = person.email;
    const phone = person.phone;
    const message = person.message;
    const servicePackage = person.servicePackage;
    const deadline = person.deadline;
    const description = person.description;
    const extra = AllExtras();
    if (!name || !email || !phone || !message) {
      alert("Boxes with the * cannot be blank!");
      return;
    }
    try {
      // eslint-disable-next-line
      const { data } = await axios.post(
        "https://pisma-server-api.onrender.com/api/v1/addData",
        {
          name,
          company,
          email,
          phone,
          message,
          servicePackage,
          deadline,
          description,
          extra,
        }
      );
    } catch (error) {
      alert(error);
    }
    setPerson({
      name: "",
      company: "",
      email: "",
      phone: "",
      message: "",
      servicePackage: "",
      deadline: "",
      description: "",
      extra: "",
    });
    window.location.assign("/thank-you");
  };

  return (
    <article className="ProjectInfo">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <Navigator />

      <div style={{ height: `${boxHeight + 100}px` }}></div>
      <form
        ref={boxRef}
        className="ProjectInfoForm"
        method="POST"
        name="ContactUs"
        data-netlify="true"
        onSubmit="submit"
      >
        <input type="hidden" name="form-name" value="ContactUs" />
        <h1
          style={{
            color: "rgba(1, 10, 10, 0.819)",
            fontFamily: "'Playfair Display', serif",
            textAlign: "center",
          }}
        >
          Complete the following form with the necessary information you require
        </h1>
        <div className="simple_info">
          <div className="insideDiv">
            <h3>
              Full Name <span style={{ color: "red" }}>*</span>
            </h3>
            <input
              type="text"
              name="name"
              value={person.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="insideDiv">
            <h3>Company</h3>
            <input
              type="text"
              name="company"
              value={person.company}
              onChange={handleChange}
              placeholder="If applicable"
            />
          </div>
        </div>

        <div className="simple_info">
          <div className="insideDiv">
            <h3>
              Email <span style={{ color: "red" }}>*</span>
            </h3>
            <input
              type="text"
              name="email"
              value={person.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="insideDiv">
            <h3>
              Phone <span style={{ color: "red" }}>*</span>
            </h3>
            <input
              type="text"
              name="phone"
              value={person.phone}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="insideDivPackage">
          <h3>Please select or write the package you need</h3>
          <input
            list="browsers"
            name="servicePackage[]"
            id="browser"
            value={person.servicePackage}
            onChange={handleChange}
          />
          <datalist id="browsers">
            <option value="Simple package from £500" />
            <option value="Advanced package from £2.000" />
            <option value="Gold package from £10.000" />
          </datalist>
        </div>

        <div className="PIservicesContainer">
          <ul className="ks-cboxtags">
            <h3
              style={{
                color: "rgba(36, 35, 35, 0.819)",
                fontFamily: "'Playfair Display', serif",
              }}
            >
              Please select the services you want:
            </h3>

            <li>
              <input
                type="checkbox"
                ref={appRef}
                id="checkboxOne"
                value="App Development"
              />
              <label htmlFor="checkboxOne">App Development</label>
            </li>
            <li>
              <input
                type="checkbox"
                id="checkboxFour"
                ref={webRef}
                value="Website Development"
              />
              <label htmlFor="checkboxFour">Website Development</label>
            </li>
            <li>
              <input
                type="checkbox"
                ref={markRef}
                id="checkboxFive"
                value="Marketing"
              />
              <label htmlFor="checkboxFive">Marketing</label>
            </li>
            <li>
              <input
                type="checkbox"
                ref={seoRef}
                id="checkboxSeven"
                value="SEO"
              />
              <label htmlFor="checkboxSeven">SEO</label>
            </li>
            <li>
              <input
                type="checkbox"
                ref={itMRef}
                id="checkboxEight"
                value="IT Management"
              />
              <label htmlFor="checkboxEight">IT Management</label>
            </li>
          </ul>
        </div>

        <div className="DescriptionDiv">
          <h3>Description</h3>
          <textarea
            type="text"
            name="description"
            value={person.description}
            onChange={handleChange}
            placeholder="Please describe the operation of your project and its effects..."
          />
        </div>

        <div className="insideDiv">
          <h3>Let us know till you need it.</h3>
          <input
            type="date"
            name="deadline"
            value={person.deadline}
            onChange={handleChange}
          />
        </div>

        <div
          className="DescriptionDiv"
          style={{
            marginTop: "1%",
          }}
        >
          <h3>
            Please write any additional information{" "}
            <span style={{ color: "red" }}>*</span>
          </h3>
          <textarea
            type="text"
            name="message"
            value={person.message}
            onChange={handleChange}
            placeholder="Additional information..."
            required
          />
        </div>
        <button className="SubmitButton" style={{ margin: 25 }}>
          SEND
        </button>
      </form>
      <div className="FormDiv"></div>
    </article>
  );
}
