import React from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import Footer from "../../Utils/Footer/Footer";
import "./styles/BlogPage.css";

export default function BlogDevelopment() {
  return (
    <article>
      <Navigator />
      <article className="blogArticle">
        <h1>Practical Use of Open Source Code Softwares</h1>
        <p>
          &nbsp;&nbsp;&nbsp;As we started the project of our web site, we knew
          that the proprietary software costs would be too high for our
          financial resources. Our only option then was to make use of Open
          Source Code softwares.
          <br />
          <br />
          &nbsp;&nbsp;&nbsp; However, within the Open Source Code there are lots
          of softwares to choose from, and it´s up to the system
          analyst/programmer to pick the ones that best suit the project goals.
          In our case, we picked Linux as our operating system. However we faced
          a big question: Which Linux distribution ? There are dozens of linux
          distributions, each one with specific advantages, sometimes with or
          without paid services. We chose linux Slackware because we thought
          that it would suit better our needs, in terms of stability and
          reliability.
          <br />
          <br />
          &nbsp;&nbsp;&nbsp; Slackware is a conservative distribution, meaning
          that it only offers in its distribution package kernel versions and
          third party libraries, once it´s been proved to be stable and
          reliable. As a result, Slackware is one the most stable Linux
          operating system on the market.
          <br />
          <br />
          &nbsp;&nbsp;&nbsp; If the user wishes the most recent Linux kernel and
          libraries, we recommend to look for a different distribution. Another
          reason for us picking Slackware, was because of its text interface,
          which at the beginning may become a bit difficult, in the long run
          however it offers a better understanding of the Linux environment.
          This way most of Slackware configurations are done thru text scripts.
          Once it was set the operating system, we picked Apache as our web
          server.
          <br />
          <br />
          &nbsp;&nbsp;&nbsp; Apache is one of the most popular web server in the
          internet. For server programming language, we chose PHP. Also, a very
          popular programming language. Our experience with Apache/PHP proves
          that this setup is very reliable, working extremely well in our
          production environment. For workstation programming language, we chose
          javascript, which besides of having a simple syntax, it offers lots of
          resources. Apache web server is really a complete web server. Also, it
          can become very complex with many configuration setups.
          <br />
          <br />
          &nbsp;&nbsp;&nbsp; For our database, we picked Postgresql. This
          database has been made available to the public for more than 15 years,
          and besides of being well debugged, it offers so many resources that
          it has become one of the best database options from Open Source Code
          softwares. <br />
          <br />
          &nbsp;&nbsp;&nbsp;Extremely reliable, Postgresql has reported cases
          where there have been databases in production with over 4 terabytes.
          Besides all of that, Postresql is not the most popular Open Source
          Code database. A matter of marketing ? Probably. As for the
          programming language PHP, we had to use an object oriented application
          structure. There are many PHP application structures being offered
          with Open Source Code license. Object oriented application is very
          important, because it makes programming very easy and scalable up to
          the point where the system may grow without many problems. <br />
          <br />
          &nbsp;&nbsp;&nbsp;Also, it helps to keep PHP code separated from HTML,
          with the help of some specific libraries, such as Smarty. We make use
          of Smarty library and we believe that it is a very important resource,
          which can even make web pages faster thru the use of its cache
          functions. Also a very important set of libraries worth mentioning for
          PHP systems, is the PEAR libraries. In PEAR web site, among the many
          libraries being offered, there are the database interface DB and the
          MAIL_QUEUE, which sends emails thru the use of database. <br />
          <br />
          &nbsp;&nbsp;&nbsp;As we came to the end of our project, we had to use
          some sort of real time database replication. For that we chose the
          DRBD and Heartbeat libraries. The use of database replication is very
          important and should not be postponed. Just after 3 months our system
          got into production, our server hard disk became bad. <br />
          <br />
          &nbsp;&nbsp;&nbsp;Thru the use of DRBD/Heartbeat we were able to put
          the system back into production within seconds, without loosing any
          data. DRBD/Heartbeat replication is done thru the use of a lan network
          between the server and slave nodes. Our project also makes use of
          ImageMagick (enlarge and reduce images), Iptables (firewall),
          Tsearch2(text search in portgresql) and Mon (linux monitoring tasks).
          <br />
          <br />
          &nbsp;&nbsp;&nbsp; Also a very important library worth mentioning is
          the pseudo APC compiler for PHP. Speed is always a critical factor for
          internet sites.
        </p>
      </article>
      <Footer />
    </article>
  );
}
