import "./App.css";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Blog from "./Pages/Blog/Blog";
import Services from "./Pages/Services/Services";
import ContactUs from "./Pages/ContactUs/ContactUs";
import BlogAPCSP from "./Pages/Blog/BlogAPCSP";
import BlogCareer from "./Pages/Blog/BlogCareer";
import BlogComputers from "./Pages/Blog/BlogComputers";
import BlogDevelopment from "./Pages/Blog/BlogDevelopment";
import BlogMarketing from "./Pages/Blog/BlogMarketing";
import BlogITmanagement from "./Pages/Blog/BlogITmanagement";
import BlogOpenSource from "./Pages/Blog/BlogOpenSource";
import BlogProtect from "./Pages/Blog/BlogProtect";
import BlogRegistry from "./Pages/Blog/BlogRegistry";
import BlogSecurity from "./Pages/Blog/BlogSecurity";
import BlogSEO from "./Pages/Blog/BlogSEO";
import BlogSpyware from "./Pages/Blog/BlogSpyware";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/pisma-blog" element={<Blog />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/pisma-blog/AP-CSP" element={<BlogAPCSP />} />
        <Route path="/pisma-blog/career" element={<BlogCareer />} />
        <Route path="/pisma-blog/computers" element={<BlogComputers />} />
        <Route path="/pisma-blog/development" element={<BlogDevelopment />} />
        <Route path="/pisma-blog/marketing" element={<BlogMarketing />} />
        <Route
          path="/pisma-blog/IT-management"
          element={<BlogITmanagement />}
        />
        <Route path="/pisma-blog/open-source" element={<BlogOpenSource />} />
        <Route
          path="/pisma-blog/protect-your-computer"
          element={<BlogProtect />}
        />
        <Route path="/pisma-blog/registry" element={<BlogRegistry />} />
        <Route path="/pisma-blog/security" element={<BlogSecurity />} />
        <Route path="/pisma-blog/SEO" element={<BlogSEO />} />
        <Route path="/pisma-blog/spyware" element={<BlogSpyware />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
