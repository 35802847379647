import React from "react";
import ContactForm from "../../Utils/ContactForm/ContactForm";
import Footer from "../../Utils/Footer/Footer";
import Navigator from "../../Utils/Navigation/Navigator";
import ProjectProcess from "../Home/ProjectProcess";
import ServicesInclude from "../Home/ServicesInclude";
import ServicesMenu from "./ServicesMenu";

import Consistency from "../AboutUs/images/project-management-gfdda3d871_1920.jpg";
import Reliability from "../AboutUs/images/organization-chart-g0c874aada_1280.png";
import Experience from "../AboutUs/images/man-g1febc51fd_1280.png";
import color184529 from "../AboutUs/images/color184529.png";
import "./styles/ServicePage.css";

import { useParams } from "react-router-dom";
export default function Services() {
  const { id } = useParams();

  return (
    <article>
      <Navigator />
      <div className="ServicesHeroSection">
        <h1>Our Services</h1>
        <p>Expert Software Development Tailored to Your Needs</p>
      </div>

      <div className="ServicePageIntro">
        <div className="ServicePageIntroLeftSide">
          <h1>Why Choose Pisma as Your Premier Development Partner?</h1>
          <p>
            At Pisma, we understand that software development is a multifaceted
            process, encompassing various critical phases. Our approach begins
            with the meticulous preparation of your idea or service by our
            experienced project management team. We engage in thorough
            discussions with you to fully grasp your requirements, ensuring that
            each project is strategically planned and executed with precision.
            The key to our success lies in our unique development methodology.
            Initially, our project managers hold informal sessions with clients
            to flesh out the general concept of the project. This collaborative
            environment allows us to capture your vision effectively. Post the
            initial discussion, our team crafts a detailed prototype, which is
            presented in a subsequent meeting. This prototype serves as a
            tangible representation of your final product, facilitating a
            comprehensive understanding of your requirements. It's this
            iterative process that positions our developers to create software
            solutions that truly resonate with your business needs. Throughout
            the project lifecycle, our project managers maintain transparent
            communication, keeping you informed of the progress. This commitment
            to client engagement and excellence is what sets Pisma apart in the
            industry.
          </p>
        </div>

        <div className="ServicePageIntroRightSide">
          <ServicesInclude />
        </div>
      </div>
      <ServicesMenu idServ={id} />
      <article className="secondRowServicesPage">
        <div className="rowDiv">
          <img
            src={Consistency}
            alt="Consistency"
            className="rowImageAboutPage"
          />
          <h1>Consistency</h1>
          <p>
            One of the pillars of Pisma is consistency, which is the only way to
            get the best results.
          </p>
        </div>
        <div className="rowDiv">
          <img
            src={Reliability}
            alt="Reliability"
            className="rowImageAboutPage"
          />
          <h1>Reliability</h1>
          <p>
            The most fundamental thing that concerns us is the reliability that
            we instill in our clients.
          </p>
        </div>
        <div className="rowDiv">
          <img
            src={Experience}
            alt="Experience"
            className="rowImageAboutPage"
          />
          <h1>Experience</h1>
          <p>
            Pisma team with lots of experience in the field are easily
            accessible to clients.
          </p>
        </div>
      </article>
      <ProjectProcess />
      <div style={{ height: "100px" }}></div>
      <div className="contactAboutPage">
        <img src={color184529} alt="backG" className="contactAboutPageImg" />
        <ContactForm />
      </div>
      <Footer />
    </article>
  );
}
