import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styles/ServicesMenu.css";
import appIcons from "./images/app-icons.webp";
import webIcons from "./images/web-icons.webp";
import advertisingIcons from "./images/advertising-icons.webp";
import SEOicons from "./images/SEO-icons.webp";
import managementIcons from "./images/management-icons.webp";
import appDev from "./images/application.webp";
import webDev from "./images/webDevelopment.webp";
import marketing from "./images/marketing.webp";
import SEO from "./images/SEO.webp";
import management from "./images/management.webp";

export default function ServicesMenu() {
  const [activeButton, setActiveButton] = useState("AppBtn");
  const [displayContent, setDisplayContent] = useState({
    AppBtn: "",
    WebBtn: "none",
    AdvBtn: "none",
    SeoBtn: "none",
    ManagBtn: "none",
  });

  const handleClick = (event) => {
    setActiveButton(event.currentTarget.id);
    setDisplayContent({
      AppBtn: event.currentTarget.id === "AppBtn" ? "" : "none",
      WebBtn: event.currentTarget.id === "WebBtn" ? "" : "none",
      AdvBtn: event.currentTarget.id === "AdvBtn" ? "" : "none",
      SeoBtn: event.currentTarget.id === "SeoBtn" ? "" : "none",
      ManagBtn: event.currentTarget.id === "ManagBtn" ? "" : "none",
    });
  };

  return (
    <article className="ServicesMenu">
      <h4 className="overviewServices">OVERVIEW</h4>
      <h4 className="ourServices">OUR SERVICES</h4>
      <menu className="theMenu">
        <div
          className={
            activeButton === "AppBtn" ? "theMenuBtnActive" : "theMenuBtn"
          }
          id="AppBtn"
          onClick={handleClick}
        >
          <img src={appIcons} alt="Application Development" />
        </div>
        <div
          className={
            activeButton === "WebBtn" ? "theMenuBtnActive" : "theMenuBtn"
          }
          id="WebBtn"
          onClick={handleClick}
        >
          <img src={webIcons} alt="Website Development" />
        </div>
        <div
          className={
            activeButton === "AdvBtn" ? "theMenuBtnActive" : "theMenuBtn"
          }
          id="AdvBtn"
          onClick={handleClick}
        >
          <img src={advertisingIcons} alt="Marketing" />
        </div>
        <div
          className={
            activeButton === "SeoBtn" ? "theMenuBtnActive" : "theMenuBtn"
          }
          id="SeoBtn"
          onClick={handleClick}
        >
          <img src={SEOicons} alt="seo" />
        </div>
        <div
          className={
            activeButton === "ManagBtn" ? "theMenuBtnActive" : "theMenuBtn"
          }
          id="ManagBtn"
          onClick={handleClick}
        >
          <img src={managementIcons} alt="IT-management" />
        </div>
      </menu>
      <div className="infoPerMenuBtn">
        <div className="webServ" style={{ display: displayContent.AppBtn }}>
          <img
            src={appDev}
            alt="Application Development"
            style={{ height: "100%" }}
          />
          <div className="servBtnInfo">
            <h3>Application Development</h3>
            <p>
              &nbsp;&nbsp;&nbsp;In summary, app development serves as a
              formidable lever for business growth. By crafting bespoke
              applications tailored for popular platforms, businesses can not
              only tap into existing user bases but also explore untapped
              markets. Apps offer the added advantages of boosting customer
              engagement, fortifying loyalty, and enhancing overall
              satisfaction. Given these manifold benefits, investing in app
              development should be a top priority for businesses looking to
              make a meaningful impact.
              <br />
              <br /> &nbsp;&nbsp;&nbsp;In today's digital ecosystem,
              smartphones, televisions, and PCs reign supreme. Just imagine the
              potential of having an application for your business across these
              popular devices. The advantages are numerous—increased visibility,
              broader customer reach, and heightened engagement, to name a few.
              And that's just the tip of the iceberg. With a well-crafted app,
              the growth possibilities for your business are virtually
              limitless.
            </p>
            <h5>
              To dive deeper and discover how we can elevate your business,
              click the button below.
            </h5>
            <Link
              className="ServiceButtonMoreHP"
              to={"/services"}
              state={{ scrollTo: "AppBtn" }}
            >
              <button className="ServiceButtonMore"> Learn more</button>
            </Link>
          </div>
        </div>
        <div className="webServ" style={{ display: displayContent.WebBtn }}>
          <img src={webDev} alt="Website Development" />
          <div className="servBtnInfo">
            <h3>Website Development</h3>
            <p>
              &nbsp;&nbsp;&nbsp;Enhancing your online presence and expanding
              your customer reach has never been easier, thanks to website
              development. If you're eager to take that next step, consider
              partnering with a specialized web development company like ours.
              We're committed to crafting a website that is meticulously
              tailored to meet your unique needs and objectives. Reach out to us
              today to jumpstart your digital journey.
              <br />
              <br /> &nbsp;&nbsp;&nbsp; In today's digital age, a website is no
              longer a luxury—it's a necessity for businesses across all
              sectors. Having a website is the most reliable way to expand your
              customer base and establish your presence in the market. Whether
              you're a startup or a well-established enterprise, an online
              platform serves as your gateway to endless opportunities.
            </p>
            <h5>
              To dive deeper and discover how we can elevate your business,
              click the button below.{" "}
            </h5>

            <Link
              className="ServiceButtonMoreHP"
              to={"/services"}
              state={{ scrollTo: "WebBtn" }}
            >
              <button className="ServiceButtonMore"> Learn more</button>
            </Link>
          </div>
        </div>
        <div className="webServ" style={{ display: displayContent.AdvBtn }}>
          <img src={marketing} alt="Marketing" />
          <div className="servBtnInfo">
            <h3>Marketing for your business</h3>
            <p>
              &nbsp;&nbsp;&nbsp; If you're not actively marketing your business,
              you're leaving untapped potential on the table. A well-crafted
              marketing plan and budget can be game-changers, allowing you to
              reach a wider audience and effectively showcase your offerings.
              Don't miss out on the opportunity to connect with potential
              customers. Take the initiative and start building your marketing
              strategy today!
              <br />
              <br /> &nbsp;&nbsp;&nbsp; Having a corporate website or
              application is just the first step; visibility is key to
              leveraging these digital assets. In today's interconnected world,
              online marketing is paramount, and proven channels like Google Ads
              and Social Media Ads are instrumental in increasing your digital
              footprint. These platforms not only boost awareness but also drive
              targeted traffic, ensuring your services reach the audience they
              were designed for.
            </p>
            <h5>
              To dive deeper and discover how we can elevate your business,
              click the button below.
            </h5>
            <Link
              className="ServiceButtonMoreHP"
              to={"/services"}
              state={{ scrollTo: "AdvBtn" }}
            >
              <button className="ServiceButtonMore"> Learn more</button>
            </Link>
          </div>
        </div>
        <div className="webServ" style={{ display: displayContent.SeoBtn }}>
          <img src={SEO} alt="SEO" />
          <div className="servBtnInfo">
            <h3>SEO</h3>
            <p>
              &nbsp;&nbsp;&nbsp; While SEO may seem intricate and daunting, it's
              crucial for the success of your website. The good news is that
              abundant resources are available to demystify SEO and guide you
              through the optimization process. If you're ready to take your
              online presence to the next level, there's no time like the
              present. Dive into some research and begin optimizing your site
              today to reach your target audience more effectively.
              <br />
              <br /> &nbsp;&nbsp;&nbsp; Achieving the coveted number one spot on
              search engines involves a multi-faceted approach to Search Engine
              Optimization (SEO). The goal is to optimize your website in such a
              way that it climbs to the top of a Search Engine Results Page
              (SERP), thereby increasing visibility and driving more traffic to
              your site. This involves a blend of on-page and off-page SEO
              strategies, including keyword research, quality content creation,
              backlink building, and technical optimizations. Each of these
              elements contributes to improving your site's ranking, with the
              ultimate aim of capturing that elusive top spot.
            </p>
            <h5>
              To dive deeper and discover how we can elevate your business,
              click the button below.{" "}
            </h5>
            <Link
              className="ServiceButtonMoreHP"
              to={"/services"}
              state={{ scrollTo: "SeoBtn" }}
            >
              <button className="ServiceButtonMore"> Learn more</button>
            </Link>
          </div>
        </div>
        <div className="webServ" style={{ display: displayContent.ManagBtn }}>
          <img src={management} alt="IT Management" />
          <div className="servBtnInfo">
            <h3>IT Management for your business</h3>
            <p>
              &nbsp;&nbsp;&nbsp; Equipped with cutting-edge technology but
              unsure why it's not yielding results? What if you had experts in
              your corner who not only understand the technology but also know
              how to build it from the ground up? With a team like that backing
              you, you'd gain actionable insights and tailored solutions to turn
              your tech investments into tangible success. Don't just settle for
              owning the technology—leverage it to its full potential with the
              right guidance and expertise.
              <br />
              <br /> &nbsp;&nbsp;&nbsp; If you're in the market for a trusted,
              seasoned IT management team to elevate your business, your search
              ends here. With our deep knowledge and extensive experience, we'll
              guide you through the implementation of cutting-edge technologies
              and industry trends. Reach out to us today to discover how we can
              propel your business to unprecedented heights.
            </p>
            <h5>
              To dive deeper and discover how we can elevate your business,
              click the button below.
            </h5>
            <Link
              className="ServiceButtonMoreHP"
              to={"/services"}
              state={{ scrollTo: "ManagBtn" }}
            >
              <button className="ServiceButtonMore"> Learn more</button>
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
}
