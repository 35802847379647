import React, { useEffect, useState, useRef } from "react";
import "./styles/PismaIntro.css";
import businessImage from "./images/business.webp";
import { init } from "ityped";
import { useNavigate } from "react-router-dom";

const PismaIntro = () => {
  const textRef = useRef();
  let triggerOnce = true;
  const navigator = useNavigate();
  useEffect(() => {
    if (triggerOnce) {
      triggerOnce = false;
      init(textRef.current, {
        showCursor: false,
        strings: [
          "Web Applications",
          "Mobile Applications",
          "Digital Experiences",
        ],
        backDelay: 1500,
        backSpeed: 60,
      });
    }
  }, []);

  return (
    <div className="pisma-intro">
      <img
        src={businessImage}
        alt="Business"
        className="background-image"
        loading="lazy"
      />
      <div className="intro-content">
        <h1>
          Crafting <span ref={textRef}></span>
        </h1>
        <p>
          Your vision, our expertise. Together, let's build something amazing.
        </p>
        <button
          className="contact-btn"
          onClick={() => navigator("/contact-us")}
        >
          Get in Touch
        </button>
      </div>
    </div>
  );
};

export default PismaIntro;
