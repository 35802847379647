import React from "react";
import Navigator from "../../Utils/Navigation/Navigator";
import Footer from "../../Utils/Footer/Footer";
import "./styles/BlogPage.css";

export default function BlogDevelopment() {
  return (
    <article>
      <Navigator />
      <article className="blogArticle">
        <h1>
          New Data: AP CSP Course is Diversifying Computer Science Pipeline
        </h1>
        <p>
          &nbsp;&nbsp;&nbsp;For too long there has been a shortage of women and
          a lack of diversity in both computer science classrooms and in the
          workforce. AP Computer Science Principles, or AP CSP, was introduced
          by the College Board four years ago to change the invitation to
          computer science and address the shortage of young women and students
          of color entering the fields of science, technology, engineering, and
          math (STEM).Now, there is encouraging news about efforts to prepare
          more students –and a more diverse set of students — for the next
          generation of computer science jobs.
          <br />
          <br />
          &nbsp;&nbsp;&nbsp; According to new College Board data, students from
          the class of 2019 who took Advanced Placement Computer Science
          Principles (AP CSP) were three times more likely to declare a computer
          science major in college, compared to similar students who did not
          take AP CSP. Differences are similarly large for female, Black,
          Hispanic, and first-generation college students.New research out today
          is good news for business leaders looking to hire the next generation
          of computer science workers. <br />
          <br />
          &nbsp;&nbsp;&nbsp;Students from the class of 2019 who took Advanced
          Placement Computer Science Principles were three times more likely to
          declare a computer science major in college, according to College
          Board data.AP Computer Science Principles, or AP CSP, was designed by
          the College Board four years ago to change the invitation to computer
          science and address a shortage of young women and students of color
          entering the fields of science, technology, engineering, and math
          (STEM), long dominated by young white and Asian men. <br />
          <br />
          &nbsp;&nbsp;&nbsp;"Four years ago, we set out to change the invitation
          to computer science education," says College Board Global Policy Chief
          Stefanie Sanford. "This new research provides compelling evidence that
          AP CSP is doing exactly that.""We are inspired to see how this course
          is playing a role in students’ high school and college careers just
          four years after its launch. States, schools, and educators providing
          access to AP CSP are ensuring a more representative body of students
          will become our next generation of computer scientists, engineers, and
          technology leaders."College freshmen who declare a computer science
          major take a critical step toward receiving the second-highest-paid
          college degree in the nation, according to the National Association of
          Colleges and Employers. <br />
          <br />
          &nbsp;&nbsp;&nbsp;The median annual wage for computer and information
          technology occupations was $88,240 in May 2019. And yet, a code.org
          analysis of 2017 Bureau of Labor Statistics data finds women represent
          just 24 percent of the 5 million people in computing occupations,
          while 15 percent are Black or Hispanic.The College Board study also
          finds AP CSP students are nearly twice as likely to enroll in AP
          Computer Science A (AP CSA) — a more computer-programming-focused
          course — compared to similar students who did not take CSP. <br />
          <br />
          &nbsp;&nbsp;&nbsp;Black students who take AP CSP are three times more
          likely to also take AP CSA. Notably, that’s virtually the same share
          as Asian CSP students, who have long led AP CSA participation.Business
          leaders such as Shanika Hope, head of the U.S. division of Amazon
          Future Engineer, are enthusiastic about AP Computer Science Principles
          and its future economic impact. "At Amazon, we know a high-quality
          computer science education is key to helping students build their best
          and brightest future, especially those from underserved and
          underrepresented communities. <br />
          <br />
          &nbsp;&nbsp;&nbsp;We’re excited that today’s research shows further
          evidence that courses like AP CSP — which we provide for thousands of
          our Amazon Future Engineer schools across the U.S. — are preparing a
          more diverse group of students to enter the 21st century
          workforce."The first year of AP Computer Science Principles in 2016-17
          attracted more students than any other AP course debut, and
          participation is on the rise. <br />
          <br />
          &nbsp;&nbsp;&nbsp;Still, not all students have access to high-quality
          computer science education. Currently, less than half of the nation’s
          high schools teach foundational computer science. States are working
          to broaden participation in computer science by passing policies to
          make computer science a fundamental part of the K-technology12
          education system.
        </p>
      </article>
      <Footer />
    </article>
  );
}
